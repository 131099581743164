








































import { Vue, Prop, Component, Model } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
    @Model('changePage')
    private currentPage!: number;

    @Prop({ default: 0 })
    private pagesCount!: number;

    @Prop({ default: 'light' })
    private theme!: 'light' | 'dark';

    private get userPage(): number {
        return this.currentPage + 1;
    }

    private get pages(): number[] {
        if (this.pagesCount === 2) {
            return [1, 2];
        }

        if (this.userPage === 1 || this.userPage === 2 || this.pagesCount === 3) {
            return [1, 2, 3];
        }

        if (this.userPage === this.pagesCount) {
            return [this.userPage - 2, this.userPage - 1, this.userPage];
        }

        return [this.userPage - 1, this.userPage, this.userPage + 1];
    }

    private changePage(page: number): void {
        this.$emit('changePage', page);
    }
}
