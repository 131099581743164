




















































import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class BattleInfo extends Vue {
    @Prop({ default: '' })
    private finishDate!: string;

    @Prop({ default: '' })
    private startDate!: string;

    @Prop({ default: '' })
    private openDate!: string;

    @Prop({ default: 0 })
    private participantsAmount!: number;

    @Prop({ type: Boolean, default: false })
    private hideRegister!: boolean;
}
