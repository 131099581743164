































import { Vue, Component, Prop } from 'vue-property-decorator';
import PortfolioName from '@/components/PortfolioName.vue';
import InvestBlock from '@/components/InvestBlock.vue';
import { PortfolioModule } from '@/store/modules/portfolio';
import AppButton from '@/components/controls/AppButton.vue';
import { IAddedCoin, ICoin } from '@/store/types';
import Notification from '@/components/Notification/notify';

@Component({
    components: { AppButton, InvestBlock, PortfolioName }
})
export default class DashboardBalance extends Vue {
    @Prop({ default: '' })
    private battleId!: number;

    private investInput: string = '';

    private get balance(): number {
        return PortfolioModule.balance;
    }

    private get addedCoins(): IAddedCoin[] {
        return PortfolioModule.addedCoins;
    }

    private get selectedCoin(): ICoin {
        return PortfolioModule.selectedCoin;
    }

    private addCoin(): void {
        if (Number(this.investInput) > 400) {
            Notification({
                msg: this.$t('maxSumPopup'),
            });

            return;
        }

        PortfolioModule.addCoin({
            coin_id: this.selectedCoin.id,
            amount_usd: Number(this.investInput),
            logo_url: this.selectedCoin.logo_url,
            price: this.selectedCoin.price,
            symbol: this.selectedCoin.symbol,
        });

        PortfolioModule.selectCoin({} as ICoin);
        this.investInput = '';
    }
}
