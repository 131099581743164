






















import { Prop, Vue, Component } from 'vue-property-decorator';
import { IAddedCoin } from '@/store/types';
import { round } from '@/utils';
import { PortfolioModule } from '@/store/modules/portfolio';
import CoinListSmall from '@/components/leaders/CoinListSmall.vue';
@Component({
    components: { CoinListSmall }
})
export default class EditCoins extends Vue {
    @Prop({ default: () => [] })
    private coins!: IAddedCoin[];

    @Prop({ type: Boolean, default: false })
    private editing!: boolean;

    private round(num: number): number {
        return round(num);
    }

    private deleteAdded(index: number): void {
        PortfolioModule.deleteAddedCoin(index);

        if (this.coins.length === 0) {
            this.$emit('close', false);
        }
    }
}
