




































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import LeaderboardItem from '@/components/LeaderboardItem.vue';
import { UserModule } from '@/store/modules/user';
import { IBattleInfo, ILeader } from '@/store/types';
import { BattleModule, COUNT_LEADERS_PER_PAGE } from '@/store/modules/battle';
import Ads from '@/components/Ads.vue';
import Pagination from '@/components/Pagination.vue';
import { PortfolioModule } from '@/store/modules/portfolio';
import AppPopup from '@/components/controls/AppPopup.vue';
import Loader from '@/components/Loader.vue';
import Header from '@/components/common/Header.vue';
import BattleInfoLarge from '@/components/leaders/BattleInfoLarge.vue';
import http from '@/http';

@Component({
    components: { BattleInfoLarge, Header, Loader, AppPopup, Pagination, Ads, LeaderboardItem }
})
export default class Leaderboard extends Vue {
    @Prop({ default: () => {} })
    private battleInfo!: IBattleInfo;

    private readonly countPerPage: number = COUNT_LEADERS_PER_PAGE;

    private loading: boolean = true;

    private currentPage: number = 0;

    private leaderWorker: any;

    private runi: ILeader | null = null;

    private get pagesCount(): number {
        return Math.ceil(this.leadersCount / this.countPerPage);
    }

    private get me(): ILeader {
        return BattleModule.meInLeaderBoard;
    }

    private get leadersCount(): number {
        return BattleModule.leadersCount;
    }

    private get leaders(): { [page: number]: ILeader[] } {
        return BattleModule.leaders;
    }

    private get currentleaders(): ILeader[] {
        return this.leaders[this.currentPage] ?? [];
    }

    private get shouldShowMe(): boolean {
        if (this.currentPage !== 0 || !this.isAuthed || !this.me) {
            return false;
        } else {
            return !this.currentleaders.find(l => l.its_mine);
        }
    }

    private get isAuthed(): boolean {
        return UserModule.isAuthed;
    }

    private async created(): Promise<void> {
        this.loading = true;
        await Promise.all([
            BattleModule.getLeaders(),
            PortfolioModule.getCoins(),
            this.getRuni(),
        ]);

        this.leaderWorker = setInterval(() => {
            BattleModule.getLeaders(this.currentPage);
            this.getRuni();
        }, 20000);

        this.loading = false;
        BattleModule.getLeaders(this.currentPage + 1);
        BattleModule.getLeaders(this.currentPage + 2);
        BattleModule.getLeaders(this.pagesCount - 1);
    }

    @Watch('currentPage')
    private async onCurrentPageChange(val: number, prev: number): Promise<void> {
        BattleModule.getLeaders(val);

        if (val > prev) {
            if (val < this.pagesCount - 1) {
                BattleModule.getLeaders(val + 1);
            }

            if (val < this.pagesCount - 2) {
                BattleModule.getLeaders(val + 2);
            }
        } else {
            if (val > 0) {
                BattleModule.getLeaders(val - 1);
            }

            if (val > 1) {
                BattleModule.getLeaders(val - 2);
            }
        }
    }

    private mounted(): void {
        window.scrollTo({
            top: 0,
        });
    }

    private beforeDestroy(): void {
        clearInterval(this.leaderWorker);
    }

    private async getRuni(): Promise<void> {
        try {
            const res = await http.get(`battles/${this.battleInfo.id}/leaders/3283`);

            this.runi = res.data;
        } catch (error) {
            this.runi = null;
        }
    }
}
