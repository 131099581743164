








import { Vue, Prop, Component, Model } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
    @Model('close')
    private readonly isShown!: boolean;

    @Prop({ type: Boolean, default: false })
    private closeOutside!: boolean;

    private closeModal(): void {
        if (this.closeOutside) {
            this.$emit('close', false);
        }
    }
}
