







import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import TermsPage from '@/views/TermsPage.vue';
import Dashboard from '@/views/Dashboard.vue';
import Leaderboard from '@/views/Leaderboard.vue';
import { UserModule } from '@/store/modules/user';
import eventBus from '@/utils/eventBus';
import { BattleModule } from '@/store/modules/battle';
import { IBattleInfo, ICoin, IPortfolio, UserStage } from '@/store/types';
import Loader from '@/components/Loader.vue';
import { PortfolioModule } from '@/store/modules/portfolio';
import { language, LanguageModule } from '@/store/modules/language';

@Component({
    components: { Loader, Leaderboard, Dashboard, TermsPage }
})
export default class BattlesViewHandler extends Vue {
    @Prop({ default: '0' })
    private id!: string;

    private loading: boolean = false;

    private battleInfo: IBattleInfo = BattleModule.upcomingBattles[this.id] || BattleModule.liveBattles[this.id] || BattleModule.finishedBattles[this.id];

    private get isAuthed(): boolean {
        return UserModule.isAuthed;
    }

    private get portfolioName(): string {
        return UserModule.currentPortfolioName;
    }

    private get currentTime(): Date {
        return BattleModule.currentTime;
    }

    private get currentLanguage(): language {
        return LanguageModule.currentLanguage;
    }

    private get currentStage(): UserStage {
        if (!this.battleInfo) {
            return 'waiting';
        }

        if (Number(this.currentTime) < Number(new Date(this.battleInfo.open_date))) {
            return 'waiting';
        }

        if (Number(this.currentTime) < Number(new Date(this.battleInfo.start_date))) {
            if (!this.portfolioName) {
                return 'portfolioName';
            }

            return 'prepare';
        }

        if (Number(this.currentTime) < Number(new Date(this.battleInfo.finish_date))) {
            return 'active';
        }

        return 'finished';
    }

    @Watch('isAuthed')
    private onIsAuthedChange(val: boolean): void {
        if (!val) {
            this.$router.replace({ name: 'BattlesListPage' });
        }
    }

    @Watch('currentLanguage')
    private async onLanguageChange(): Promise<void> {
        this.loading = true;

        const res = await BattleModule.getBattleById(this.id);

        if (res.status === 200) {
            this.battleInfo = res.data;
        }

        this.loading = false;
    }

    private async created(): Promise<void> {
        this.loading = true;

        BattleModule.setBattleId(Number(this.id));

        const res = await BattleModule.getBattleById(this.id);

        if (res.status === 200) {
            this.battleInfo = res.data;

            if (this.battleInfo.fountain_image_url) {
                eventBus.emit('changeClickSrc', this.battleInfo.fountain_image_url);
            }
        } else {
            await this.$router.replace({ path: '/notfound' });

            return;
        }

        const isStarted = Number(this.currentTime) >= +new Date(this.battleInfo.start_date);

        if (!this.isAuthed && !isStarted) {
            await this.$router.replace({ name: 'BattlesListPage' });

            eventBus.emit('pleaseAuth');

            return;
        }

        if (!this.battleInfo.can_enter && !isStarted) {
            await this.$router.replace({ name: 'BattlesListPage' });

            return;
        }

        await PortfolioModule.getPortfolio();

        if (!PortfolioModule.isPublished) {
            UserModule.setCurrentInfoFromTemplate(Number(this.id));
        }

        this.loading = false;

        window.addEventListener('beforeunload', this.saveBattleTemplate);
    }

    private beforeDestroy(): void {
        this.saveBattleTemplate();

        BattleModule.clearLeaders();
        BattleModule.setBattleId(-1);
        PortfolioModule.setPublishedPortfolio({} as IPortfolio);
        PortfolioModule.selectCoin({} as ICoin);
        UserModule.setPortfolioName('');
        PortfolioModule.removeAddedCoins();
        window.removeEventListener('beforeunload', this.saveBattleTemplate);

        eventBus.emit('changeClickSrc', '/img/moni-eye.svg');
    }

    private saveBattleTemplate(): void {
        if (this.currentStage === 'finished' || this.currentStage === 'active' || this.currentStage === 'waiting') {
            return;
        }

        if (!PortfolioModule.isPublished || PortfolioModule.isEditable) {
            UserModule.setSavedTemplate({
                battleId: this.battleInfo.id,
                template: { name: UserModule.currentPortfolioName, items: PortfolioModule.addedCoins, updatedDate: 0 },
            });
        }
    }
}
