




























































import { Vue, Prop, Component } from 'vue-property-decorator';
import { ICoin, ILeader } from '@/store/types';
import { PortfolioModule } from '@/store/modules/portfolio';
import CoinListSmall from '@/components/leaders/CoinListSmall.vue';
import CoinListLarge from '@/components/leaders/CoinListLarge.vue';

@Component({
    components: { CoinListLarge, CoinListSmall }
})
export default class LeaderboardItem extends Vue {
    @Prop({ default: () => ({}) })
    private leader!: ILeader;

    private isCoinsPopupShown: boolean = false;

    private get isProfited(): boolean {
        return Number(this.leader.roi) >= 0;
    }

    private get initialBalance(): number {
        return PortfolioModule.initialBalance;
    }

    private get coins(): { [key: string]: ICoin } {
        const marketCoins: { [key: string]: ICoin } = {};

        if (!this.leader.items || PortfolioModule.coins.length === 0) {
            return {};
        }

        this.leader.items.forEach(coin => {
            const founded = PortfolioModule.coins.find(c => c.id === coin.coin_id);

            marketCoins[coin.coin_id] = founded as ICoin;
        });

        return marketCoins;
    }

    private showCoinsPopup(): void {
        if (window.innerWidth > 1024) {
            return;
        }

        if (this.isCoinsPopupShown) {
            this.isCoinsPopupShown = false;

            return;
        }

        this.isCoinsPopupShown = true;
    }

    private closeCoinsPopup(): void {
        this.isCoinsPopupShown = false;
    }
}
