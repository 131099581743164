
















































import { Vue, Prop, Component } from 'vue-property-decorator';
import AppInput from '@/components/controls/AppInput.vue';
import AppButton from '@/components/controls/AppButton.vue';
import Notification from '@/components/Notification/notify';
import { PortfolioModule } from '@/store/modules/portfolio';
import Terms from '@/components/Terms.vue';
import Header from '@/components/common/Header.vue';
import { IBattleInfo } from '@/store/types';
import BattleInfo from '@/components/battlesList/BattleInfo.vue';
import { UserModule } from '@/store/modules/user';

@Component({
    components: { BattleInfo, Header, Terms, AppButton, AppInput }
})
export default class TermsPage extends Vue {
    @Prop({ default: () => {} })
    private battleInfo!: IBattleInfo;

    private portfolioName: string = '';

    private isError: boolean = false;

    private get maxPortfolioNameLength(): number {
        return UserModule.maxPortfolioNameLength;
    }

    private async setPortfolioName(): Promise<void> {
        if (this.portfolioName.length > this.maxPortfolioNameLength) {
            Notification({
                msg: this.$t('tooLongName'),
            });

            return;
        }

        const res = await PortfolioModule.checkNameFree(this.portfolioName);

        if (res.status === 200) {
            UserModule.setPortfolioName(this.portfolioName);
        } else if (res.status === 409) {
            Notification({
                msg: this.$t('portfolioExist'),
            });
        }
    }

    private onContinueClick(): void {
        if (!this.portfolioName) {
            Notification({
                msg: `
                    <div style="display: flex; flex-direction: column; align-items: center; margin-top: -15px;">
                        <img style="width: 34px; margin-bottom: 6px" src="/img/emoji/stop-emoji.png" alt="fire">
                        <span>
                            ${this.$t('fillPortfolioName')}
                        </span>
                    </div>
                `,
            });

            this.isError = true;
        }
    }

    private onInputFocus(): void {
        this.isError = false;
    }
}
