
























import { Vue, Prop, Component } from 'vue-property-decorator';
import { IAd } from '@/store/types';

@Component
export default class Ads extends Vue {
    @Prop({ default: () => [] })
    private ads!: IAd[];
}
