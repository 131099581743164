






import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class AppLink extends Vue {
    @Prop({ default: '' })
    private to!: string;

    @Prop({ type: Boolean, default: true })
    private external!: boolean;
}
