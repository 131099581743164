















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CoinListSmall extends Vue {
    @Prop({ default: () => [] })
    private coinUrls!: string[];

    private readonly maxShowCount: number = 6;

    private get moreThanSix(): boolean {
        return this.coinUrls.length > this.maxShowCount;
    }

    private get filteredCoins(): string[] {
        return this.coinUrls.slice(0, this.maxShowCount);
    }
}
