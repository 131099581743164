





















































import { Vue, Model, Component, Watch } from 'vue-property-decorator';
import { IAddedCoin, ICoin } from '@/store/types';
import { PortfolioModule } from '@/store/modules/portfolio';
import EditCoins from '@/components/EditCoins.vue';

@Component({
    components: { EditCoins }
})
export default class InvestBlock extends Vue {
    @Model('input')
    private investValue!: string | number;

    private isEditing: boolean = false;

    private get initialBalance(): number {
        return PortfolioModule.initialBalance;
    }

    private get selectedCoin(): ICoin {
        const coin = PortfolioModule.selectedCoin;

        if (!coin.id) {
            this.$emit('input', '');
        }

        return coin;
    }

    private get addedCoins(): IAddedCoin[] {
        return PortfolioModule.addedCoins;
    }

    private get balance(): number {
        return PortfolioModule.balance;
    }

    @Watch('selectedCoin')
    private onSelectedCoinChange(val: ICoin | null): void {
        if (val?.id) {
            this.$nextTick(() => {
                (this.$refs.investInput as HTMLInputElement).focus();
            });
        }
    }

    private handleInvestValue(e: any): void {
        const value = e.target.value;

        if (value === '') {
            this.$emit('input', '');
            e.target.value = '';

            return;
        }

        if (!isNaN(value) && !value.includes('.') && !value.includes(' ') && Number(value) <= this.balance) {
            this.$emit('input', Math.round(value));
        } else {
            e.target.value = this.investValue;
        }
    }

    private handleSubmit(): void {
        if (this.investValue) {
            this.$emit('submit');
        }
    }
}
