






















































import { Vue, Prop,  Component } from 'vue-property-decorator';
import BattleInfo from '@/components/battlesList/BattleInfo.vue';
import { IBattleInfo } from '@/store/types';
import { BattleModule } from '@/store/modules/battle';
import { IS_BATTLEINFO_EXPANDED } from '@/consts/localStorage';
import {
    MILLISECONDS_IN_SECOND,
    MILLISECONDS_IN_HOUR,
    MILLISECONDS_IN_MINUTE,
    MILLISECONDS_IN_DAY
} from '@/consts/common';

@Component({
    components: { BattleInfo },
})
export default class BattleInfoLarge extends Vue {
    @Prop({ default: () => {} })
    private battleInfo!: IBattleInfo;

    private isCollapsed: boolean = localStorage.getItem(IS_BATTLEINFO_EXPANDED) === 'false';

    private get currentTime(): Date {
        return BattleModule.currentTime;
    }

    private get isFinished(): boolean {
        return +new Date(this.battleInfo.finish_date) < +this.currentTime;
    }

    private get duration(): string {
        if (this.isFinished) {
            return '';
        }

        // @ts-ignore
        const duration = this.$mom.duration(+new Date(this.battleInfo.finish_date) - this.currentTime);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const millisecondsDays = duration._milliseconds -
            seconds * MILLISECONDS_IN_SECOND -
            minutes * MILLISECONDS_IN_MINUTE -
            hours * MILLISECONDS_IN_HOUR;

        const days = Math.floor(millisecondsDays / MILLISECONDS_IN_DAY);

        return this.addZeroIfNeeded(days) + ':' +
            this.addZeroIfNeeded(hours) + ':' +
            this.addZeroIfNeeded(minutes) + ':' +
            this.addZeroIfNeeded(seconds);
    }

    private toggleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        localStorage.setItem(IS_BATTLEINFO_EXPANDED, String(this.isCollapsed));
    }

    private addZeroIfNeeded(num: number): string {
        if (num < 10) {
            return `0${num}`;
        } else {
            return String(num);
        }
    }
}
