


















import { Vue, Prop, Component } from 'vue-property-decorator';
import { ICoin, ILeaderCoin } from '@/store/types';
import { round } from '@/utils';
import AppPopup from '@/components/controls/AppPopup.vue';

@Component({
    components: { AppPopup },
})
export default class CoinListLarge extends Vue {
    @Prop({ default: () => [] })
    private leaderCoins!: ILeaderCoin[];

    @Prop({ default: () => ({}) })
    private marketCoins!: { [key: string]: ICoin };

    private isProfitable(roi: number | string): boolean {
        return Number(roi) >= 0;
    }

    private getLogoUrl(coinId: string): string {
        return this.marketCoins[coinId]?.logo_url ?? '';
    }

    private getSymbol(coinId: string): string {
        return this.marketCoins[coinId].symbol;
    }

    private round(num: number | string, decimals: number = 2, addDecimalsForLow: boolean = false): number {
        return round(Number(num), decimals, addDecimalsForLow);
    }
}
