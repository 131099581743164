




















import { Prop, Vue, Component } from 'vue-property-decorator';
import { ICoin } from '@/store/types';
import { round } from '@/utils';
import { PortfolioModule } from '@/store/modules/portfolio';

@Component
export default class Coin extends Vue {
    @Prop({ default: () => {} })
    private coin!: ICoin;

    @Prop({ type: Boolean, default: false })
    private selected!: boolean;

    @Prop({ type: Boolean, default: false })
    private added!: boolean;

    private round(num: number): number {
        return round(num, 2, true);
    }

    private get isDisabled(): boolean {
        return this.balance === 0 && !this.added;
    }

    private get balance(): number {
        return PortfolioModule.balance;
    }

    private handleSelect(): void {
        if (this.isDisabled) {
            return;
        }

        if (this.added) {
            this.$emit('delete', true);

            return;
        }

        this.$emit('select', true);
    }
}
