

















































































import { Vue, Prop, Component } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import DashboardBalance from '@/components/DashboardBalance.vue';
import Coin from '@/components/Coin.vue';
import { IAddedCoin, IAsset, IBattleInfo, ICoin } from '@/store/types';
import { PortfolioModule } from '@/store/modules/portfolio';
import AppButton from '@/components/controls/AppButton.vue';
import Notification from '@/components/Notification/notify';
import { BattleModule } from '@/store/modules/battle';
import { Debounce } from '@/utils';
import { ADDED_COINS } from '@/consts/localStorage';
import Modal from '@/Modal.vue';
import Terms from '@/components/Terms.vue';
import Loader from '@/components/Loader.vue';
import Header from '@/components/common/Header.vue';

@Component({
    components: { Header, Loader, Terms, Modal, AppButton, Coin, DashboardBalance }
})
export default class Dashboard extends Vue {
    @Prop({ default: () => {} })
    private battleInfo!: IBattleInfo;

    private loading: boolean = true;

    private searchString: string = '';

    private filteredCoins: ICoin[] = [];

    private coinWorker: any;

    private isTermsModalOpen: boolean = false;

    private get isEditable(): boolean {
        return PortfolioModule.isEditable;
    }

    private get selectedCoin(): ICoin {
        return PortfolioModule.selectedCoin;
    }

    private get balance(): number {
        return PortfolioModule.balance;
    }

    private get coins(): ICoin[] {
        return PortfolioModule.coins;
    }

    private get isPublished(): boolean {
        return PortfolioModule.isPublished;
    }

    private get addedCoins(): IAddedCoin[] {
        return PortfolioModule.addedCoins;
    }

    private get currentTime(): Date {
        return BattleModule.currentTime;
    }

    private get timeLeft(): string {
        // @ts-ignore
        const duration = this.$mom.duration(+new Date(this.battleInfo.start_date) - this.currentTime);
        const days = this.addZeroIfNeeded(duration.days());
        const hours = this.addZeroIfNeeded(duration.hours());
        const minutes = this.addZeroIfNeeded(duration.minutes());
        const seconds = this.addZeroIfNeeded(duration.seconds());

        return days + ':' + hours + ':' + minutes + ':' + seconds;
    }

    private async mounted(): Promise<void> {
        this.loading = true;

        const res = await PortfolioModule.getPortfolio();

        if (res.status === 401) {
            UserModule.signout();

            return;
        }

        await PortfolioModule.getCoins();

        this.coinWorker = setInterval(() => PortfolioModule.getCoins(), 60000);

        await this.filterCoins();

        if (this.isPublished) {
            const coins = PortfolioModule.coins;
            const publishedCoins = PortfolioModule.publishedPortfolio?.items ?? [];

            // @ts-ignore
            await PortfolioModule.setAddedCoins(publishedCoins.map((pCoin: IAsset) => {
                const coin = coins.find(c => c.id === pCoin.coin_id) as ICoin;

                return {
                    coin_id: pCoin.coin_id,
                    amount_usd: pCoin.amount_usd,
                    logo_url: coin.logo_url,
                    symbol: coin.symbol,
                    price: coin.price,
                };
            }));
        }

        await this.$nextTick();
        this.loading = false;

        window.scrollTo({
            top: 0,
        });
    }

    private beforeDestroy(): void {
        clearInterval(this.coinWorker);
    }

    private async publishPortfolio(): Promise<void> {
        if (!PortfolioModule.isPublished) {
            // @ts-ignore
            // eslint-disable-next-line
            ym(65707168, 'reachGoal', 'battle_done');
        }

        const res = await PortfolioModule.createPortfolio();

        if (res.status === 200) {
            localStorage.removeItem(ADDED_COINS);

            Notification({
                msg: `
                <div style="display: flex; flex-direction: column; align-items: center; margin-top: -15px;">
                    <img style="width: 34px; margin-bottom: 6px" src="/img/emoji/fire-emoji-round.png" alt="fire">
                    <span>
                        ${this.$t('portfolioCreated')}
                    </span>
                </div>
            `,
            });
        } else if (res.status === 409) {
            Notification({
                msg: this.$t('portfolioExist'),
            });
        } else if (res.status === 400) {
            Notification({
                msg: this.$t('maxSumPopup'),
            });
        }
    }

    private closeTermsModal(): void {
        this.isTermsModalOpen = false;
        Notification({
            msg: `
                <div style="display: flex; flex-direction: column; align-items: center; margin-top: -15px;">
                    <img style="width: 34px; margin-bottom: 6px" src="/img/emoji/cute-emoji-round.png" alt="cute">
                    <span>
                        ${this.$t('iUnderstood')}
                    </span>
                </div>
            `,
        });
    }

    @Debounce(500)
    private filterCoins(): void {
        this.filteredCoins = this.coins.filter(coin => {
            const symbol = coin.symbol.toLowerCase();
            const name = coin.name.toLowerCase();
            const searchString = this.searchString.toLowerCase();

            return symbol.includes(searchString) || name.includes(searchString);
        });
    }

    private selectCoin(coin: ICoin): void {
        if (this.selectedCoin.id === coin.id) {
            PortfolioModule.selectCoin({} as ICoin);
        } else {
            PortfolioModule.selectCoin(coin);
        }
    }

    private deleteCoin(coin: ICoin): void {
        const index = this.addedCoins.findIndex(c => c.coin_id === coin.id);
        if (index === -1 ) {
            return;
        }
        PortfolioModule.deleteAddedCoin(index);
    }

    private clearSearch(): void {
        this.searchString = '';
        this.filterCoins();
    }

    private addZeroIfNeeded(num: number): string {
        if (num < 10) {
            return `0${num}`;
        } else {
            return String(num);
        }
    }
}
