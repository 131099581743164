

















import { Vue, Prop, Component } from 'vue-property-decorator';
import Notification from '@/components/Notification/notify';
import { PortfolioModule } from '@/store/modules/portfolio';
import { UserModule } from '@/store/modules/user';

@Component
export default class PortfolioName extends Vue {
    @Prop({ default: '' })
    private battleId!: number;

    private newPortfolioName: string = '';

    private isEditing: boolean = false;

    private get portfolioName(): string {
        return UserModule.currentPortfolioName;
    }

    private async toggleEdit(): Promise<void> {
        if (this.isEditing) {
            if (this.newPortfolioName === this.portfolioName) {
                this.isEditing = false;

                return;
            }

            if (this.newPortfolioName.length > 20) {
                Notification({
                    msg: this.$t('tooLongName'),
                });

                return;
            }

            const res = await PortfolioModule.checkNameFree(this.newPortfolioName);

            if (res.status === 409) {
                Notification({
                    msg: this.$t('portfolioExist'),
                });

                return;
            }

            UserModule.setPortfolioName(this.newPortfolioName);

            if (PortfolioModule.isPublished) {
                PortfolioModule.updatePortfolioName();
            }

            this.isEditing = false;
        } else {
            this.isEditing = true;
            this.newPortfolioName = this.portfolioName;

            this.$nextTick(() => (this.$refs.input as HTMLInputElement).focus());
        }
    }
}
