































import { Vue, Prop, Component } from 'vue-property-decorator';
import AppLink from '@/components/controls/AppLink.vue';
import { ITerm } from '@/store/types';

@Component({
    components: { AppLink }
})
export default class Terms extends Vue {
    @Prop({ default: () => {} })
    private terms!: ITerm[];
}
