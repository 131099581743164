import { render, staticRenderFns } from "./CoinListLarge.vue?vue&type=template&id=419508b4&scoped=true&"
import script from "./CoinListLarge.vue?vue&type=script&lang=ts&"
export * from "./CoinListLarge.vue?vue&type=script&lang=ts&"
import style0 from "./CoinListLarge.vue?vue&type=style&index=0&id=419508b4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419508b4",
  null
  
)

export default component.exports